
import { computed, defineComponent, ref } from 'vue';
import './Drones.scss';

import store from '@/store';

import globalToast from '@/composables/common/globalToast';
import droneNumbers from '@/composables/drones/droneNumbers';

export default defineComponent({
    setup() {
        const { showGlobalToast } = globalToast();
        const { loadDroneNumbers, addDrone, deleteDrone } = droneNumbers();

        const droneNumbersList = computed(() => store.getters.g_droneNumbers);

        const newdrone = ref('');

        const addDroneNumber = () => {
            let dronenumber = newdrone.value;
            if(dronenumber != ''){
                addDrone(dronenumber);
                newdrone.value = '';
            } else {
                showGlobalToast('Enter Drone Number...');
            }
        }

        const deleteDroneNumber = (e: any) => {
            let dronenumber = e.target.getAttribute('dronenumber');
            // console.log(dronenumber);
            deleteDrone(dronenumber);
        }
        return { 
            droneNumbersList, loadDroneNumbers,
            newdrone, addDroneNumber, deleteDroneNumber
        }
    },
})
